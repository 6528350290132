import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

interface Props {
  item: Article;
}

interface Article {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  description: string;
  content: string;
  image: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin: 2px 2px 12px 2px;
  width: 200px;
  height: 300px;

  background-color: #fff;
  border: 2px;

  @media (max-width: 900px) {
    width: 250px;
    height: 375px;
  }
`;

const ItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 100%;
  height: 260px;
  overflow: hidden;
  background-color: #333;

  @media (max-width: 900px) {
    height: 325px;
  }
`;

const Image = styled.img`
  width: 100%;

  text-align: center;
  font-size: 12px;
  color: #9d9d9d;

  background-size: contain;
`;

const DefaultImageText = styled.p`
  font-size: 18px;
  color: #9d9d9d;
`;

const ItemTitleContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 8px 24px 8px;
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: 4px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 15px;
  font-weight: bold;
  color: #454545;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  height: 18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 1.5;
  color: #666666;

  @media (max-width: 900px) {
    height: 20px;
    font-size: 16px;
  }
`;

const ArticleListItem = (props: Props) => {
  const { item } = props;
  const { id, title, image, subtitle } = item;
  const history = useHistory();

  const handleClick = () => history.push(`/article/${id}`);

  return (
    <Wrapper>
      <ItemImage onClick={handleClick}>
        {image ? (
          <Image src={image} alt={title} />
        ) : (
          <DefaultImageText>失蹤者之城</DefaultImageText>
        )}
      </ItemImage>
      <ItemTitleContent>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </ItemTitleContent>
    </Wrapper>
  );
};

export default ArticleListItem;

import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Article, fetchDeleteArticle } from '../../actions/article';
import { EditOutlined, Delete } from '@material-ui/icons';
import {
  TableCell,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';

const IconStyle = css`
  cursor: pointer;
  color: #666;
  font-size: 26px !important;
`;

const IconEdit = styled(EditOutlined)`
  ${IconStyle}
`;

const IconDelete = styled(Delete)`
  ${IconStyle}
`;

interface Props {
  article: Article;
}

const ArticleInfo: FC<Props> = props => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { article } = props;
  const { title, author, category, vol, id } = article;

  const dispatch = useDispatch();

  const history = useHistory();

  const handleOnEdit = () => {
    history.push(`/admin/article/edit/${id}`);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{author}</TableCell>
      <TableCell>{category}</TableCell>
      <TableCell>{vol}</TableCell>
      <TableCell>
        <IconEdit onClick={handleOnEdit} />
      </TableCell>
      <TableCell>
        <IconDelete onClick={handleDialogOpen} />
      </TableCell>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>{`確認刪除 ${title}？`}</DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              handleDialogClose();
              dispatch(fetchDeleteArticle(id));
            }}
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default ArticleInfo;

import {
  GET_ARTICLELIST,
  GET_ARTICLELIST_FULFILLED,
  GET_ARTICLELIST_REJECTED,
  LOAD_MORE_ARTICLELIST,
  LOAD_MORE_ARTICLELIST_FULFILLED,
  LOAD_MORE_ARTICLELIST_REJECTED,
  getArticleList,
  getArticleListFulfilled,
  getArticleListRejected,
  loadMoreArticleList,
  loadMoreArticleListFulfilled,
  loadMoreArticleListRejected
} from '../actions/articleList';

import { ArticleInfo } from '../actions/article';

type Actions =
  | ReturnType<typeof getArticleList>
  | ReturnType<typeof getArticleListFulfilled>
  | ReturnType<typeof getArticleListRejected>
  | ReturnType<typeof loadMoreArticleList>
  | ReturnType<typeof loadMoreArticleListFulfilled>
  | ReturnType<typeof loadMoreArticleListRejected>;

interface State {
  isFetching: boolean;
  isLoadingMore: boolean;
  listInfo: ArticleInfo;
  errorMessage: string;
}

const initialState: State = {
  isFetching: false,
  isLoadingMore: false,
  listInfo: {
    total: 0,
    page: 0,
    length: 0,
    articles: []
  },
  errorMessage: ''
};

export const articleList = (state = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ARTICLELIST:
      return {
        ...state,
        isFetching: true
      };
    case GET_ARTICLELIST_FULFILLED:
      return {
        ...state,
        isFetching: false,
        listInfo: action.payload
      };
    case GET_ARTICLELIST_REJECTED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case LOAD_MORE_ARTICLELIST:
      return {
        ...state,
        isLoadingMore: true
      };
    case LOAD_MORE_ARTICLELIST_FULFILLED:
      return {
        ...state,
        isLoadingMore: false,
        listInfo: {
          ...action.payload,
          articles: state.listInfo.articles.concat(action.payload.articles)
        }
      };
    case LOAD_MORE_ARTICLELIST_REJECTED:
      return {
        ...state,
        isLoadingMore: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

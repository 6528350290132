import { createApiClient } from './apiClient';
import { Token } from '../reducers/login';

const apiClient = createApiClient();

export const login = async (user: string, password: string) => {
  const response = await apiClient.post<Token>('/login', {
    user,
    password
  });

  return response.data;
};

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ArticleItem from './ArticleItem';

interface Article {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  description: string;
  content: string;
  image: string;
}

interface Category {
  routePath: string;
  iconText: string;
  title: string;
  description: string;
  articles: Article[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 10px;
  padding: 20px;
  width: 900px;

  @media (max-width: 900px) {
    width: 580px;
  }
  @media (max-width: 580px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 15px;
  width: 100%;

  text-align: left;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  @media (max-width: 580px) {
    justify-content: center;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
  width: 80px;
  height: 80px;

  font-size: 55px;
  font-weight: bold;
  color: #fff;
  background-color: #212121;
  border-radius: 50%;

  @media (max-width: 580px) {
    margin: 0 0 10px 0;
  }
`;

const DescriptionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 580px) {
    align-items: center;
  }
`;

const Title = styled.div`
  margin-bottom: 10px;

  font-size: 24px;
  font-weight: bold;
  color: #323232;
`;

const Description = styled.div`
  line-height: 20px;
  text-align: left;
  font-size: 15px;
  color: #454545;
`;

const Button = styled.div`
  position: relative;
  margin-top: 4px;
  align-self: flex-start;
  cursor: pointer;
  color: #0069b4;
  font-size: 16px;
  font-weight: 500;
  ::before {
    position: absolute;
    bottom: -6px;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #0069b4;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-out;
  }
  :hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  @media (max-width: 900px) {
    font-size: 22px;
  }
  @media (max-width: 580px) {
    align-self: center;
  }
`;

// function ArticleBlock(props) {
const ArticleBlock = (props: Category) => {
  const { articles, iconText, title, description, routePath } = props;
  const history = useHistory();

  const handleGoToCategory = () => {
    history.push(`/categories/${routePath}`);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Icon>{iconText}</Icon>
        <DescriptionWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </DescriptionWrapper>
      </TitleWrapper>
      <ArticleWrapper>
        {articles.map((item, index) => (
          <ArticleItem key={index} item={item} />
        ))}
      </ArticleWrapper>
      <Button onClick={handleGoToCategory}>更多文章</Button>
    </Wrapper>
  );
};

export default ArticleBlock;

import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingWrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const IndicatorBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50px;
`;

const IndicatorAni = keyframes`
  0%, 80%, 100% { transform: scale(1); }
  40% { transform: scale(2); }
`;

const Indicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #fff;
  animation: ${IndicatorAni} 1.6s infinite ease-in-out;
`;
const IndicatorOne = styled(Indicator)`
  animation-delay: -0.32s;
`;
const IndicatorTwo = styled(Indicator)`
  animation-delay: -0.16s;
`;

const LoadingIcon = () => (
  <LoadingWrapper>
    <Loading>
      <IndicatorBox>
        <Indicator></Indicator>
        <IndicatorOne></IndicatorOne>
        <IndicatorTwo></IndicatorTwo>
      </IndicatorBox>
    </Loading>
  </LoadingWrapper>
);

export default LoadingIcon;

import React, { FC, useState, useEffect } from 'react';
import TextEditor from '../../../components/TextEditor/TextEditor';
import DetailSection from './DetailSection';
import {
  CreatedArticle,
  fetchCreateArticle,
  fetchArticle,
  fetchEditArticle
} from '../../../actions/article';
import { defaultArticle } from '../../../reducers/article';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../reducers';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddEditArticle: FC = () => {
  const dispatch = useDispatch();
  const { mode, id } = useParams<{ mode: string; id: string }>();
  const articles = useSelector((state: RootState) => state.article.articles);
  const [article, setArticle] = useState<CreatedArticle>(defaultArticle);

  useEffect(() => {
    if (mode === 'edit') {
      if (id) {
        dispatch(fetchArticle(parseInt(id, 10) || 1));
      }
    }
  }, [mode, id, dispatch]);

  useEffect(() => {
    if (id && articles[parseInt(id, 10)]) {
      setArticle(articles[parseInt(id, 10)]);
    }
  }, [id, articles]);

  const handleOnCreate = () => {
    if (mode === 'edit') {
      dispatch(
        fetchEditArticle({
          ...article,
          id: parseInt(id, 10)
        })
      );
    } else {
      dispatch(fetchCreateArticle(article));
    }
  };

  return (
    <Wrapper>
      <TextEditor article={article} setArticle={setArticle} />
      <Column>
        <DetailSection article={article} setArticle={setArticle} />

        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          GOOO
        </Button>
      </Column>
    </Wrapper>
  );
};

export default AddEditArticle;

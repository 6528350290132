import { combineReducers } from 'redux';
import { category as categoryReducer } from './category';
import { login as loginReducer } from './login';
import { articleList as articleListReducer } from './articleList';
import { article as articleReducer } from './article';
import { image as imageReducer } from './image';

const rootReducer = combineReducers({
  category: categoryReducer,
  login: loginReducer,
  articleList: articleListReducer,
  article: articleReducer,
  image: imageReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import React from 'react';
import styled from 'styled-components';
import { getLandingPage, LandingPage } from '../services/landingPage';

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 14% 10px calc(15% - 15px);
  background-color: #212121;
  @media (max-width: 768px) {
    padding: 10px calc(5% - 15px) 10px calc(10% - 15px);
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const Contact = styled(Location)`
  width: 160px;
`;

const FooterTitle = styled.h4`
  color: #d3d3d3;
  font-size: 18px;
  margin: 10px 15px 0 15px;
  padding: 0 0 2px 0;
  border-bottom: 1px solid #d3d3d3;
`;

const LocationInfo = styled.div`
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: 430px) {
    max-height: 300px;
  }
`;

const LocationInfoDetail = styled.ul`
  min-width: 120px;
  display: flex;
  flex-direction: column;
  padding: 6px 15px 0 15px;
`;

const LocationTitle = styled.h5`
  color: #d3d3d3;
  font-size: 14px;
  margin-bottom: 5px;
`;
const LocationText = styled.a`
  text-decoration: none;
  color: #808080;
  font-size: 14px;
  line-height: 1.5;
`;

const ContactText = styled.p`
  color: #d3d3d3;
  font-size: 14px;
  line-height: 1.5;
`;

class Footer extends React.Component {
  state: LandingPage['footer'] = {
    email: '',
    shop: []
  };

  async componentDidMount() {
    try {
      const data = await getLandingPage();

      this.setState(data);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { shop, email } = this.state;

    return (
      <Wrapper>
        <Location>
          <FooterTitle>販售地點</FooterTitle>
          <LocationInfo>
            {shop.map(({ location: locationName, list }, locationIdx) => (
              <LocationInfoDetail key={`${locationIdx}_${locationName}`}>
                <LocationTitle>{locationName}</LocationTitle>
                {list.map(({ title: shopName, pageLink }, shopIdx) => (
                  <li key={`${shopIdx}_${shopName}`}>
                    <LocationText href={pageLink}>{shopName}</LocationText>
                  </li>
                ))}
              </LocationInfoDetail>
            ))}
          </LocationInfo>
        </Location>
        <Contact>
          <FooterTitle>聯絡我們</FooterTitle>
          <LocationInfoDetail>
            <ContactText>投稿或任何意見</ContactText>
            <ContactText as="a">{email}</ContactText>
          </LocationInfoDetail>
        </Contact>
      </Wrapper>
    );
  }
}

export default Footer;

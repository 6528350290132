const REFRESH_TOKEN = 'REFRESH_TOKEN';
const ACCESS_TOKEN = 'ACCESS_TOKEN';

const Storage = {
  getRefreshToken: () => {
    return window.localStorage.getItem(REFRESH_TOKEN);
  },
  setRefreshToken: (authTime: string) => {
    window.localStorage.setItem(REFRESH_TOKEN, authTime);
  },
  removeRefreshToken: () => {
    window.localStorage.removeItem(REFRESH_TOKEN);
  },
  getAccessToken: () => {
    return window.localStorage.getItem(ACCESS_TOKEN);
  },
  setAccessToken: (accessToken: string) => {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  },
  removeAccessToken: () => {
    window.localStorage.removeItem(ACCESS_TOKEN);
  }
};

export default Storage;

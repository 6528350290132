import { getCategoryById } from '../services/category';
import { AppThunk } from '../store';

export interface CategoryInfo {
  total: number;
  page: number;
  length: number; // 每頁幾筆
  articles: Article[];
}
export interface Article {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  description: string;
  content: string;
  image: string;
}

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_FULFILLED = 'GET_CATEGORY_FULFILLED';
export const GET_CATEGORY_REJECTED = 'GET_CATEGORY_REJECTED';
export const LOAD_MORE_CATEGORY = 'LOAD_MORE_CATEGORY';
export const LOAD_MORE_CATEGORY_FULFILLED = 'LOAD_MORE_CATEGORY_FULFILLED';
export const LOAD_MORE_CATEGORY_REJECTED = 'LOAD_MORE_CATEGORY_REJECTED';

export const getCategory = () =>
  ({
    type: GET_CATEGORY
  } as const);

export const getCategoryFulfilled = (payload: CategoryInfo) =>
  ({
    type: GET_CATEGORY_FULFILLED,
    payload
  } as const);

export const getCategoryRejected = (payload: string) =>
  ({
    type: GET_CATEGORY_REJECTED,
    payload
  } as const);

export const loadMoreCategory = () =>
  ({
    type: LOAD_MORE_CATEGORY
  } as const);

export const loadMoreCategoryFulfilled = (payload: CategoryInfo) =>
  ({
    type: LOAD_MORE_CATEGORY_FULFILLED,
    payload
  } as const);

export const loadMoreCategoryRejected = (payload: string) =>
  ({
    type: LOAD_MORE_CATEGORY_REJECTED,
    payload
  } as const);

export const fetchCategoryById = (
  categoryId: string
): AppThunk => async dispatch => {
  try {
    dispatch(getCategory());
    const result = await getCategoryById(categoryId, 0);
    dispatch(getCategoryFulfilled(result));
  } catch (error) {
    dispatch(getCategoryRejected(error.toString()));
  }
};
export const fetchMoreCategoryById = (categoryId: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadMoreCategory());
    const { page } = getState().category.categoryInfo;
    const result = await getCategoryById(categoryId, page + 1);
    dispatch(loadMoreCategoryFulfilled(result));
  } catch (error) {
    dispatch(loadMoreCategoryRejected(error.toString()));
  }
};

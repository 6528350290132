import React, { FC, useState, useRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  RecordVoiceOverOutlined,
  PublicOutlined,
  StarOutlined,
  ViewWeekOutlined,
  FaceOutlined
} from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

import SubNav from './SubNav';

const Wrapper = styled.ul<{ showNav: boolean }>`
  display: flex;
  @media (max-width: 768px) {
    position: fixed;
    z-index: 1;
    top: 80px;
    left: 0;
    flex-direction: column;
    align-items: center;
    padding: 5% 10% 20%;
    display: ${props => (props.showNav ? 'flex' : 'none')};
    width: 80vw;
    height: calc(100vh - 80px);
    background-color: #333333;
  }
`;

const NavLi = styled.li<{ sub: boolean }>`
  ${props =>
    props.sub &&
    `
    position: relative;
    z-index: 1;
    cursor: pointer;
    }`};
  @media (max-width: 768px) {
    margin: 0 0 3.5vh 0px;
  }
`;

const NavLink = styled.a<{ sub: boolean; activeStatus: boolean }>`
  position: relative;
  padding-bottom: 15px;
  margin: ${props => (props.sub ? '0 5px 0 15px' : '0 15px')};
  cursor: pointer;
  color: #9d9d9d;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  border-bottom: 0px solid #9d9d9d;
  ::before {
    position: absolute;
    bottom: 10px;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #9d9d9d;
    transform: ${props => (props.activeStatus ? 'none' : 'scaleX(0)')};
    transform-origin: right;
    transition: transform 0.4s ease-out;
  }
  :hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  ${props =>
    props.sub &&
    `
    ::after {
      margin: 0 0 3px 5px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #9d9d9d transparent transparent transparent;
      transform: translateY(0px);
      transition: transform 0.4s ease-out;
    }`};
  :hover::after {
    transform: translateY(1px);
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin: 0 15px;
    padding: 10px;
    ::before,
    ::after {
      display: none;
    }
  }
`;

const IconStyle = css`
  display: none !important;
  @media (max-width: 768px) {
    margin-right: 16px;
    display: inline-block !important;
    color: #ccc;
  }
`;

const IconInterview = styled(RecordVoiceOverOutlined)`
  ${IconStyle};
`;

const IconTranslate = styled(PublicOutlined)`
  ${IconStyle};
`;

const IconRecommend = styled(StarOutlined)`
  ${IconStyle};
`;

const IconColumn = styled(ViewWeekOutlined)`
  ${IconStyle};
`;

const IconAbout = styled(FaceOutlined)`
  ${IconStyle};
`;

const getIcon = (icon: string) => {
  switch (icon) {
    case 'IconInterview':
      return IconInterview;
    case 'IconTranslate':
      return IconTranslate;
    case 'IconRecommend':
      return IconRecommend;
    case 'IconColumn':
      return IconColumn;
    case 'IconAbout':
      return IconAbout;
    default:
      return IconInterview;
  }
};

interface Props {
  showNav: boolean;
  showSubNav?: boolean;
  sub?: boolean;
  menu: {
    title: string;
    icon: string;
    link: string;
    subMenu?: {
      title: string;
      link: string;
    }[];
  }[];
}

const Nav = forwardRef<HTMLUListElement, Props>(({ menu, showNav }, ref) => {
  const node = useRef<HTMLLIElement>(null);
  const [showSubNav, setShowSubNav] = useState(false);
  const location = useLocation();

  return (
    <Wrapper showNav={showNav} ref={ref}>
      {menu.map(({ title, icon, subMenu, link }, idx) => {
        const IconComponent = getIcon(icon);
        return (
          <NavLi
            ref={subMenu ? node : null}
            sub={!!subMenu}
            key={idx}
            onMouseEnter={() => {
              subMenu && setShowSubNav(true);
            }}
            onMouseLeave={() => {
              subMenu && setShowSubNav(false);
            }}
          >
            <NavLink
              sub={!!subMenu}
              href={link}
              activeStatus={link === location.pathname}
            >
              <IconComponent />
              {title}
            </NavLink>
            {subMenu && <SubNav subMenu={subMenu} showSubNav={showSubNav} />}
          </NavLi>
        );
      })}
    </Wrapper>
  );
});

export default Nav;

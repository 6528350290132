import React, { FC, useEffect, useRef } from 'react';

interface Props {
  loadMore: () => void;
  options?: IntersectionObserverInit;
}

const InfiniteScrollObserver: FC<Props> = props => {
  const { loadMore, options } = props;
  const loader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loaderRef = loader.current;

    const observer = new IntersectionObserver(entries => {
      const target = entries[0];
      if (target && target.isIntersecting) {
        loadMore();
      }
    }, options);

    if (loaderRef) observer.observe(loaderRef);

    return () => {
      if (loaderRef) observer.unobserve(loaderRef);
    };
  }, [loadMore, options]);

  return <div ref={loader}>loading</div>;
};

export default InfiniteScrollObserver;

import React, { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CategoryList from './pages/CategoryList';
import Article from './pages/Article';
import Login from './pages/Login';
import Admin from './pages/Admin';
import PrivateRoute from './components/PrivateRoute';

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
    font-size: 100%; 
    font-family: 'HelveticaNeue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

const Main = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  background-color: #f1f1f1;
`;

const App: FC = () => {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Main>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/categories/personal">
            <Home personal />
          </Route>
          <Route path="/categories/:categoryId">
            <CategoryList />
          </Route>
          <Route path="/article/:id">
            <Article />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/admin">
            <Admin />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Main>
      <Footer />
    </Router>
  );
};

export default App;

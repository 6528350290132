import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  width: 100%;
  border-bottom: 1px solid lightgrey;

  font-family: HelveticaNeue Helvetica;
`;

const Title = styled.h2`
  margin-top: 20px;
  font-size: 30px;
  font-weight: 500;
`;

const Subtitle = styled.h3`
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
`;

const Author = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.45;
`;

interface Props {
  author: string;
  title: string;
  subtitle: string;
}

const TitleSection: FC<Props> = ({
  author = '',
  title = '',
  subtitle = ''
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    {author && <Author>by {author}</Author>}
  </Wrapper>
);

export default TitleSection;

import { createApiClient } from './apiClient';
import { Category } from './category';
import { Article } from '../actions/category';

export interface LandingPage {
  title: string;
  category: {
    interview: Category;
    translation: Category;
    recommendation: Category;
  };
  personal: {
    title: string;
    magzineImgUrl: string;
    articles: Article[];
  };
  footer: {
    email: string;
    shop: {
      location: string;
      list: {
        title: string;
        pageLink: string;
      }[];
    }[];
  };
}

const apiClient = createApiClient();

export const getLandingPage = async () => {
  const response = await apiClient.get<LandingPage>('/landing-page');
  return response.data;
};

export const getLandingPageCategory = async () => {
  const response = await apiClient.get<Category[]>('/landing-page/category');
  return response.data;
};

export const getLandingPagePersonal = async () => {
  const response = await apiClient.get<Category[]>('/landing-page/personal');
  return response.data;
};

import React, {
  useState,
  FC,
  Dispatch,
  SetStateAction,
  ChangeEvent
} from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import styled from 'styled-components';
import { CreatedArticle, ArticleCategory } from '../../../actions/article';
import ImageModal from './ImageModal';

interface Props {
  article: CreatedArticle;
  setArticle: Dispatch<SetStateAction<CreatedArticle>>;
}

const Wrapper = styled.div`
  display: grid;
  padding: 20px 0;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 20px;
`;

const Image = styled.img`
  width: 200px;
  /* height: 260px; */
`;

const categoryList: ArticleCategory[] = [
  'interview',
  'translation',
  'recommendation',
  'essay',
  'poem',
  'graphic'
];

const DetailSection: FC<Props> = props => {
  const { article, setArticle } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { type, value, valueAsNumber } = e.target;
    const inputValue = type === 'number' ? valueAsNumber : value;

    setArticle({
      ...article,
      [e.target.name]: inputValue
    });
  };

  const handleOnSelectChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setArticle({
      ...article,
      category: e.target.value as ArticleCategory
    });
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleImageSelect = (url: string) => {
    setArticle({
      ...article,
      image: url
    });
  };

  return (
    <Wrapper>
      <div>
        <h3>Thumbmail</h3>
        {article.image && <Image src={article.image} alt={article.title} />}
        <button onClick={handleClick}>上傳圖片</button>
      </div>
      {isOpen && (
        <ImageModal
          isOpen={isOpen}
          handleCloseDialog={() => setIsOpen(false)}
          handleCoverSelect={handleImageSelect}
        />
      )}

      <TextField
        name="vol"
        label="Volumn"
        value={article.vol}
        onChange={handleOnInputChange}
        variant="outlined"
        type="number"
      />
      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          name="type"
          value={article.category}
          onChange={e => handleOnSelectChange(e)}
          label="Type"
        >
          {categoryList.map(category => (
            <MenuItem value={category} key={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        name="author"
        label="Author"
        value={article.author}
        onChange={handleOnInputChange}
        variant="outlined"
      />
      <TextField
        name="title"
        label="Title"
        value={article.title}
        onChange={handleOnInputChange}
        variant="outlined"
      />
      <TextField
        name="subtitle"
        label="Subtitle"
        value={article.subtitle}
        onChange={handleOnInputChange}
        variant="outlined"
      />
      <TextField
        name="description"
        label="Description"
        value={article.description}
        onChange={handleOnInputChange}
        variant="outlined"
      />
    </Wrapper>
  );
};

export default DetailSection;

import {
  Article,
  ArticleCategory,
  GET_ARTICLE,
  GET_ARTICLE_FULFILLED,
  GET_ARTICLE_REJECTED,
  CREATE_ARTICLE_ACTION,
  CREATE_ARTICLE_FULFILLED,
  CREATE_ARTICLE_REJECTED,
  EDIT_ARTICLE_ACTION,
  EDIT_ARTICLE_FULFILLED,
  EDIT_ARTICLE_REJECTED,
  getArticle,
  getArticleFulfilled,
  getArticleRejected,
  createArticleAction,
  createArticleFulfilled,
  createArticleRejected,
  editArticleAction,
  editArticleFulfilled,
  editArticleRejected
} from '../actions/article';

type Actions =
  | ReturnType<typeof getArticle>
  | ReturnType<typeof getArticleFulfilled>
  | ReturnType<typeof getArticleRejected>
  | ReturnType<typeof createArticleAction>
  | ReturnType<typeof createArticleFulfilled>
  | ReturnType<typeof createArticleRejected>
  | ReturnType<typeof editArticleAction>
  | ReturnType<typeof editArticleFulfilled>
  | ReturnType<typeof editArticleRejected>;

export const defaultArticle = {
  id: -1,
  vol: 0,
  category: 'interview' as ArticleCategory,
  author: '',
  title: '',
  subtitle: '',
  content: [
    {
      type: 'paragraph',
      children: [{ text: 'This is editable' }]
    }
  ],
  image: '',
  description: ''
};

interface state {
  isFetching: boolean;
  articles: {
    [id: number]: Article;
  };
  errorMessage: string;
  createErrorMessage: string;
}
const initialState: state = {
  isFetching: false,
  articles: {},
  errorMessage: '',
  createErrorMessage: ''
};

export const article = (state = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        isFetching: true
      };
    case GET_ARTICLE_FULFILLED:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.id]: action.payload
        },
        isFetching: false
      };
    case GET_ARTICLE_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false
      };
    case CREATE_ARTICLE_REJECTED:
      return {
        ...state,
        createErrorMessage: action.payload
      };
    case EDIT_ARTICLE_REJECTED:
      return {
        ...state,
        createErrorMessage: action.payload
      };
    case EDIT_ARTICLE_FULFILLED:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.payload.id]: defaultArticle
        },
        isFetching: false
      };
    case CREATE_ARTICLE_FULFILLED:
    case CREATE_ARTICLE_ACTION:
    case EDIT_ARTICLE_ACTION:
    default:
      return state;
  }
};

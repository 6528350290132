import { login } from '../services/login';
import { Token } from '../reducers/login';
import { AppThunk } from '../store';
import Storage from '../services/storage';

export const POST_LOGIN = 'POST_LOGIN';
export const POST_LOGIN_FULFILLED = 'POST_LOGIN_FULFILLED';
export const POST_LOGIN_REJECTED = 'POST_LOGIN_REJECTED';

export const postLogin = () => ({ type: POST_LOGIN } as const);

export const postLoginFulFilled = (payload: Token) =>
  ({
    type: POST_LOGIN_FULFILLED,
    payload
  } as const);

export const postLoginRejected = (payload: number) =>
  ({
    type: POST_LOGIN_REJECTED,
    payload
  } as const);

export const postLoginStatus = (
  user: string,
  password: string
): AppThunk => async dispatch => {
  try {
    dispatch(postLogin());
    const result = await login(user, password);

    Storage.setAccessToken(result.accessToken);
    Storage.setRefreshToken(result.refreshToken);

    dispatch(postLoginFulFilled(result));
    window.location.href = '/admin';
  } catch (error) {
    dispatch(postLoginRejected(error.response.status));
  }
};

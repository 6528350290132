import React, { FC } from 'react';
import { Editor, Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Code,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  LooksOne,
  LooksTwo
} from '@material-ui/icons';
import styled from 'styled-components';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const Button = styled.button<{ active?: boolean }>`
  margin-right: 15px;
  border: none;
  background-color: transparent;

  color: ${props => (props.active ? 'black' : '#ccc')};

  > svg {
    font-size: 24px;
  }
`;

const toggleBlock = (editor: ReactEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (node: any) => LIST_TYPES.includes(node.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: ReactEditor, format: string) => {
  const [match]: any = Editor.nodes(editor, {
    match: n => n.type === format
  });

  return !!match;
};

const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export interface ButtonInterface {
  format: string;
  icon: string;
}
const getIconComponent = (icon: string) => {
  switch (icon) {
    case 'format_bold':
      return <FormatBold />;
    case 'format_italic':
      return <FormatItalic />;
    case 'format_underlined':
      return <FormatUnderlined />;
    case 'code':
      return <Code />;
    case 'looks_one':
      return <LooksOne />;
    case 'looks_two':
      return <LooksTwo />;
    case 'format_quote':
      return <FormatQuote />;
    case 'format_list_numbered':
      return <FormatListNumbered />;
    case 'format_list_bulleted':
      return <FormatListBulleted />;
  }
};

export const BlockButton: FC<ButtonInterface> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {getIconComponent(icon)}
    </Button>
  );
};

export const MarkButton: FC<ButtonInterface> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {getIconComponent(icon)}
    </Button>
  );
};

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance
} from 'axios';
import Storage from '../services/storage';

const baseURL = 'https://missingpeopleapi.herokuapp.com';

let apiClient: AxiosInstance;

const handleRequestSent = (config: AxiosRequestConfig) => {
  config.headers = {
    Authorization: `Bearer ${Storage.getAccessToken()}`
  };
  return config;
};

const handleRequestError = (err: AxiosError) => {
  console.log('request sent error');
  return Promise.reject(err);
};

const handleResponseSuccess = (response: AxiosResponse) => {
  console.log('response yeah');
  return response;
};

const handleResponseError = (err: AxiosError) => {
  console.log('response fuck');
  // if (err.response!.status === 401) {
  //   window.location.href = '/login';
  // }
  return Promise.reject(err);
};

export const createApiClient = (config: AxiosRequestConfig = {}) => {
  if (!apiClient) {
    apiClient = axios.create({
      baseURL,
      headers: {
        city: 'fuck'
      },
      ...config
    });

    apiClient.interceptors.request.use(handleRequestSent, handleRequestError);
    apiClient.interceptors.response.use(
      handleResponseSuccess,
      handleResponseError
    );
  }

  return apiClient;
};

import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  InsertChartOutlinedOutlined,
  InsertDriveFileOutlined,
  PhotoOutlined,
  CommentOutlined
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: calc(6% - 15px) 0px;
  width: 280px;
`;

const Tab = styled.li<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 15px 0;
  width: 220px;
  height: 60px;
  border-radius: 28px;
  background-color: ${props => (props.isActive ? '#39aec8' : '#fff')};
  :hover {
    background-color: #39aec8;
  }
`;

const TabTitle = styled.h3<{ isActive: boolean }>`
  margin: 0 0 0 30px;
  color: #666;
  color: ${props => (props.isActive ? '#fff' : '#666')};
  font-size: 24px;
  font-weight: 900;
  ${Tab}:hover & {
    color: #fff;
  }
`;

const Version = styled.p`
  margin: 25px 0 0;
  color: #ccc;
  font-size: 18px;
  font-weight: 300;
`;

const IconStyle = css<{ isActive: boolean }>`
  font-size: 42px !important;
  margin: 0 0 0 40px;
  color: ${props => (props.isActive ? '#fff' : '#666')};
  ${Tab}:hover & {
    color: #fff;
  }
`;

const IconChart = styled(InsertChartOutlinedOutlined)`
  ${IconStyle}
`;

const IconFile = styled(InsertDriveFileOutlined)`
  ${IconStyle}
`;

const IconPhoto = styled(PhotoOutlined)`
  ${IconStyle}
`;

const IconComment = styled(CommentOutlined)`
  ${IconStyle}
`;

interface Props {
  tabList: {
    title: string;
    icon: string;
    routePath: string;
  }[];
}

const getIconComponent = (icon: string) => {
  switch (icon) {
    case 'IconChart':
      return IconChart;
    case 'IconFile':
      return IconFile;
    case 'IconPhoto':
      return IconPhoto;
    case 'IconComment':
    default:
      return IconComment;
  }
};

const Sidebar: FC<Props> = ({ tabList }) => {
  const history = useHistory();
  let location = useLocation();
  const recentPath = location.pathname.substring(7);
  const [chosen, setChosen] = useState(recentPath);

  return (
    <Wrapper>
      {tabList.map(({ title, icon, routePath }, idx) => {
        const isActive = routePath === chosen;
        const IconComponent = getIconComponent(icon);
        return (
          <Tab
            key={idx}
            isActive={isActive}
            onClick={() => {
              setChosen(routePath);
              history.push(`/admin/${routePath}`);
            }}
          >
            <IconComponent isActive={isActive} />
            <TabTitle isActive={isActive}>{title}</TabTitle>
          </Tab>
        );
      })}
      <Version>版本：v1.0.0</Version>
    </Wrapper>
  );
};

export default Sidebar;

import React, { useEffect, useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import ArticleCard from './ArticleCard';
import {
  fetchCategoryById,
  fetchMoreCategoryById
} from '../../actions/category';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';
import { RootState } from '../../reducers';
import LoadingIcon from '../../components/LoadingIcon';

interface Params {
  categoryId: string;
}

interface IconTextComponent {
  [key: string]: string;
}

const CategoryWrapper = styled.div`
  width: 100%;
  margin: 65px 15px;
  box-sizing: border-box;

  max-width: 750px;
  border: 1px solid lightgray;
`;
const CategoryHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  top: -40px;
`;
const CategoryCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;

  color: white;
  font-size: 55px;
  font-weight: bold;
  background-color: #212121;
  cursor: default;
`;
const CategoryBody = styled.div`
  padding: 0 5%;
`;

const IconTextComponent: IconTextComponent = {
  interview: '訪',
  translation: '譯',
  recommendation: '推',
  personal: '個',
  poem: '詩',
  essay: '文',
  novel: '說',
  graphic: '圖'
};

const CategoryList: FC = () => {
  const { categoryId = '' } = useParams<Params>();
  const { isFetching, categoryInfo, errorMessage } = useSelector(
    (state: RootState) => state.category
  );

  const { articles, length } = categoryInfo;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategoryById(categoryId));
  }, [categoryId, dispatch]);

  const handleOnLoadMore = useCallback(() => {
    dispatch(fetchMoreCategoryById(categoryId));
  }, [dispatch, categoryId]);

  if (isFetching) {
    return <LoadingIcon />;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <CategoryWrapper>
      <CategoryHeader>
        <CategoryCircle>{IconTextComponent[categoryId]}</CategoryCircle>
      </CategoryHeader>
      <CategoryBody>
        {articles.map((article, idx) => (
          <ArticleCard key={idx} {...article} />
        ))}
      </CategoryBody>
      {/* length 不等於10 表示回來的文章數不滿一頁(即是最後一頁) */}
      {length === 10 && <InfiniteScrollObserver loadMore={handleOnLoadMore} />}
    </CategoryWrapper>
  );
};

export default CategoryList;

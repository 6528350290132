import { createApiClient } from './apiClient';
import { CategoryInfo, Article } from '../actions/category';

const apiClient = createApiClient();

export interface Category {
  routePath: string;
  iconText: string;
  title: string;
  description: string;
  articles: Article[];
}

export const getCategoryById = async (categoryID: string, page: number) => {
  const response = await apiClient.get<CategoryInfo>(
    `categories/${categoryID}`,
    {
      params: {
        page,
        length: 10
      }
    }
  );
  return response.data;
};

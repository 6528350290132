import {
  getImages as getImagesApi,
  createImage,
  deleteImg
} from '../services/image';
import { AppThunk } from '../store';

export interface ImageInfo {
  total: number;
  page: number;
  length: number; // 每頁幾筆
  images: Image[];
}
export interface Image {
  id: number;
  name: string;
  url: string;
}

export const GET_IMAGES = 'GET_IMAGES';
export const GET_IMAGES_FULFILLED = 'GET_IMAGES_FULFILLED';
export const GET_IMAGES_REJECTED = 'GET_IMAGES_REJECTED';
export const LOAD_MORE_IMAGES = 'LOAD_MORE_IMAGES';
export const LOAD_MORE_IMAGES_FULFILLED = 'LOAD_MORE_IMAGES_FULFILLED';
export const LOAD_MORE_IMAGES_REJECTED = 'LOAD_MORE_IMAGES_REJECTED';
export const CREATE_IMAGE_ACTION = 'CREATE_IMAGE_ACTION';
export const CREATE_IMAGE_FULFILLED = 'CREATE_IMAGE_FULFILLED';
export const CREATE_IMAGE_REJECTED = 'CREATE_IMAGE_REJECTED';
export const DELETE_IMAGE_ACTION = 'DELETE_IMAGE_ACTION';
export const DELETE_IMAGE_FULFILLED = 'DELETE_IMAGE_FULFILLED';
export const DELETE_IMAGE_REJECTED = 'DELETE_IMAGE_REJECTED';

export const getImages = () => ({ type: GET_IMAGES } as const);
export const getImagesFulfilled = (payload: ImageInfo) =>
  ({
    type: GET_IMAGES_FULFILLED,
    payload
  } as const);
export const getImagesRejected = (payload: string) =>
  ({
    type: GET_IMAGES_REJECTED,
    payload
  } as const);
export const loadMoreImages = () => ({ type: LOAD_MORE_IMAGES } as const);
export const loadMoreImagesFulfilled = (payload: ImageInfo) =>
  ({
    type: LOAD_MORE_IMAGES_FULFILLED,
    payload
  } as const);
export const loadMoreImagesRejected = (payload: string) =>
  ({
    type: LOAD_MORE_IMAGES_REJECTED,
    payload
  } as const);

export const fetchImage = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getImages());
    const result = await getImagesApi(0);
    dispatch(getImagesFulfilled(result));
  } catch (error) {
    dispatch(getImagesRejected(error.toString()));
  }
};
export const fetchMoreImage = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loadMoreImages());
    const { page } = getState().image.listInfo;
    const result = await getImagesApi(page + 1);
    dispatch(loadMoreImagesFulfilled(result));
  } catch (error) {
    dispatch(loadMoreImagesRejected(error.toString()));
  }
};

export const createImageAction = () => ({ type: CREATE_IMAGE_ACTION } as const);

export const createImageFulfilled = () =>
  ({
    type: CREATE_IMAGE_FULFILLED
  } as const);

export const createImageRejected = (payload: string) =>
  ({
    type: CREATE_IMAGE_REJECTED,
    payload
  } as const);

export const fetchCreateImage = (image: File): AppThunk => async dispatch => {
  try {
    dispatch(createImageAction());
    await createImage(image);
    dispatch(createImageFulfilled());
    dispatch(fetchImage());
  } catch (error) {
    dispatch(createImageRejected(error.toString()));
  }
};

export const deleteImageAction = () => ({ type: DELETE_IMAGE_ACTION } as const);

export const deleteImageFulfilled = () =>
  ({
    type: DELETE_IMAGE_FULFILLED
  } as const);

export const deleteImageRejected = (payload: string) =>
  ({
    type: DELETE_IMAGE_REJECTED,
    payload
  } as const);

export const fetchDeleteImage = (id: number): AppThunk => async dispatch => {
  try {
    await deleteImg(id);
    dispatch(deleteImageFulfilled());
    dispatch(fetchImage());
  } catch (error) {
    dispatch(deleteImageRejected(error.toString()));
  }
};

import React, { FC, FormEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { postLoginStatus } from '../actions/login';
import { RootState } from '../reducers';
import LoadingIcon from '../components/LoadingIcon';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);
  background: #fff
    url('https://images.pexels.com/photos/811838/pexels-photo-811838.jpeg');
  background-size: 100%;
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 60%);
  }
`;

const LoginContent = styled.form`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserImg = styled.img.attrs(() => ({
  src: 'https://avatars3.githubusercontent.com/u/20880956?s=460&v=4'
}))`
  width: 100px;
  object-fit: contain;
  border-radius: 50%;
`;

const Logo = styled.h2`
  margin-top: 25px;
  color: #bbb;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 3px;
`;

const LoginBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  width: 280px;
  border-bottom: 1px #bbb solid;
`;

const LoginInput = styled.input`
  padding: 10px 0 6px;
  width: 240px;
  color: #eee;
  font-size: 18px;
  font-weight: 900;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  &::placeholder {
    color: #bbb;
    font-size: 18px;
    font-weight: 300;
  }
  &:focus {
    outline: none;
  }
`;

const LoginBoxTitle = styled.p`
  position: absolute;
  top: -10px;
  color: #bbb;
  font-size: 14px;
  font-weight: 300;
`;

const LoginBoxAttention = styled.p`
  position: absolute;
  bottom: -20px;
  color: #dd0000;
  font-size: 14px;
  font-weight: 300;
`;

const VisBox = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const IconStyle = css`
  color: #ccc;
  font-size: 20px;
`;

const VisOff = styled(VisibilityOffOutlined)`
  ${IconStyle}
`;

const VisOn = styled(VisibilityOutlined)`
  ${IconStyle}
`;

const LoginButton = styled.button.attrs({
  type: 'submit'
})`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 55px 0 30px;
  width: 280px;
  height: 40px;
  border-radius: 10px;
  background-color: ${props => (props.disabled ? '#ccc' : '#39AEC8')};
  outline: none;
  border: none;
`;

const LoginButtonText = styled.p`
  padding: 0 0 0 10px;
  color: #fff;
  font-size: 20px;
  letter-spacing: 10px;
`;

const ForgetPwd = styled.a`
  padding-bottom: 2px;
  border-bottom: 1px #ccc solid;
  color: #bbb;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
`;

const Admin: FC = () => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [visibility, setVisibility] = useState(false);
  const { isFetching, errorMessage } = useSelector(
    (state: RootState) => state.login
  );

  const handleAccChange = (e: FormEvent<HTMLInputElement>) => {
    setAccount(e.currentTarget.value);
  };

  const handlePwdChange = (e: FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleGoToAdmin = (e: FormEvent) => {
    e.preventDefault();
    dispatch(postLoginStatus(account, password));
  };

  return (
    <Wrapper>
      {isFetching && <LoadingIcon />}
      <LoginContent onSubmit={handleGoToAdmin}>
        <UserImg />
        <Logo>失蹤者之城</Logo>
        <LoginBox>
          {account.length > 0 && <LoginBoxTitle>帳號：</LoginBoxTitle>}
          <LoginInput
            placeholder="帳號："
            value={account}
            onChange={handleAccChange}
          />
        </LoginBox>
        <LoginBox>
          {password.length > 0 && <LoginBoxTitle>密碼：</LoginBoxTitle>}
          <LoginInput
            placeholder="密碼："
            value={password}
            onChange={handlePwdChange}
            type={visibility ? 'text' : 'password'}
          />
          <VisBox onClick={handleVisibility}>
            {visibility ? <VisOn /> : <VisOff />}
          </VisBox>
          {errorMessage === 401 && (
            <LoginBoxAttention>帳號密碼錯誤，請重新輸入！</LoginBoxAttention>
          )}
        </LoginBox>
        <LoginButton disabled={password.length < 5}>
          <LoginButtonText>登入</LoginButtonText>
        </LoginButton>
        <ForgetPwd href="/">忘記密碼？</ForgetPwd>
      </LoginContent>
    </Wrapper>
  );
};

export default Admin;

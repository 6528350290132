import React, {
  FC,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import { CreatedArticle } from '../../actions/article';
import isHotkey from 'is-hotkey';
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps
} from 'slate-react';
import { withHistory } from 'slate-history';
import withLinks, { LinkButton, LinkOffButton } from './withLinks';
import { createEditor, Node } from 'slate';
import styled from 'styled-components';
import { BlockButton, MarkButton, toggleMark } from './Button';
import withEmbedsImg, {
  YoutubeButton,
  ImageButton,
  YoutubeElement,
  ImageElement
} from './withEmbedsImg';

const HOTKEYS: { [prop: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 750px;
  padding: 20px;

  background-color: white;
  font-size: 16px;
  line-height: 1.8;
  color: #333;

  strong {
    font-weight: bold;
  }
  u {
    font-decoration: underlne;
  }
  em {
    font-style: italic;
  }
  code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`;

const EditableWrapper = styled.div`
  flex: auto;
  > div {
    height: 100%;
  }
`;

const Toolbar = styled.div`
  position: sticky;
  top: 0;
  padding: 18px 18px 17px;
  margin-bottom: 20px;

  background-color: white;
  border-bottom: 2px solid rgb(238, 238, 238);
  box-shadow: 1px 1px 10px 1px rgb(238, 238, 238);
`;

interface Props {
  article: CreatedArticle;
  setArticle: Dispatch<SetStateAction<CreatedArticle>>;
}

const TextEditor: FC<Props> = props => {
  const { article, setArticle } = props;
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withEmbedsImg(withLinks(withHistory(withReact(createEditor())))),
    []
  );

  const handleOnChange = (value: Node[]) => {
    setArticle({
      ...article,
      content: value
    });
  };

  return (
    <Wrapper>
      <Slate editor={editor} value={article.content} onChange={handleOnChange}>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <LinkButton />
          <LinkOffButton />
          <YoutubeButton />
          <ImageButton />
        </Toolbar>
        <EditableWrapper>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey)(event.nativeEvent)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </EditableWrapper>
      </Slate>
    </Wrapper>
  );
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <a {...attributes} href={element.url as string}>
          {children}
        </a>
      );
    case 'youtubeIframe':
      return <YoutubeElement {...props} />;
    case 'image':
      return <ImageElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default TextEditor;

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import TitleSection from './TitleSection';
import { RootState } from '../../reducers';
import { fetchArticle } from '../../actions/article';
import { Article } from '../../actions/article';
import TextReader from '../../components/TextEditor/TextReader';
import LoadingIcon from '../../components/LoadingIcon';

// TODO: 和 Category 可以用同一份
const Wrapper = styled.div`
  width: 100%;
  padding: 45px 15px;
  box-sizing: border-box;

  max-width: 750px;
`;

const ArticlePage: FC = () => {
  const { id = '1' } = useParams<{ id: string }>();
  const articleNumberId = Number(id);
  const dispatch = useDispatch();
  const { isFetching, articles } = useSelector(
    (state: RootState) => state.article
  );
  const articleInfo: Article = articles[articleNumberId] || {};

  useEffect(() => {
    dispatch(fetchArticle(articleNumberId));
  }, [articleNumberId, dispatch]);

  if (isFetching) {
    return <LoadingIcon />;
  }

  const { title = '', subtitle = '', author = '', content = [] } = articleInfo;

  return (
    <Wrapper>
      <TitleSection title={title} subtitle={subtitle} author={author} />
      <TextReader content={content} />
    </Wrapper>
  );
};

export default ArticlePage;

import React, { FC, useState } from 'react';
import { Transforms, Range } from 'slate';
import { useSlate, ReactEditor, RenderElementProps } from 'slate-react';
import ImageModal from './ImageModal';
import styled from 'styled-components';
import { YouTube, Image } from '@material-ui/icons';

const Button = styled.button`
  margin-right: 15px;
  border: none;
  background-color: transparent;

  color: black;

  > svg {
    font-size: 24px;
  }
`;

const IframeContainer = styled.div`
  position: relative;
  margin: 50px 0;
  /* 16:9 */
  padding-top: 56.25%;
  overflow: hidden;
  width: 100%;
  text-align: center;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 50px;
  align-items: center;
`;

const Img = styled.img`
  /* max-width: 420px; */
  max-width: 100%;
  height: auto;
`;

const ImgCaption = styled.em`
  font-size: 14px;
`;

const insertYoutubeIframe = (editor: ReactEditor, urlCode: string) => {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  if (!isCollapsed) return;

  const youtubeIframe = {
    type: 'youtubeIframe',
    urlCode,
    children: [{ text: '' }]
  };
  Transforms.insertNodes(editor, youtubeIframe);
};

export const YoutubeButton: FC = () => {
  const editor = useSlate();
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault();
        const urlCode = window.prompt(
          'YouTube Video Code (https://www.youtube.com/watch?v="Here")'
        );

        if (!urlCode) return;
        else insertYoutubeIframe(editor, urlCode);
      }}
    >
      <YouTube />
    </Button>
  );
};

export const YoutubeElement: FC<RenderElementProps> = ({
  attributes,
  children,
  element
}) => {
  return (
    <IframeContainer>
      <Iframe
        title={`youtube-video-${element.urlCode as string}`}
        src={`https://www.youtube.com/embed/${element.urlCode as string}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      {children}
    </IframeContainer>
  );
};

const withEmbedsImg = (editor: ReactEditor) => {
  const { isVoid } = editor;

  editor.isVoid = element =>
    element.type === 'youtubeIframe' || element.type === 'image'
      ? true
      : isVoid(element);

  return editor;
};

export const ImageButton: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  return (
    <>
      <Button onClick={handleOpenDialog}>
        <Image />
      </Button>
      <ImageModal isOpen={isOpen} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

export const ImageElement: FC<RenderElementProps> = ({
  attributes,
  children,
  element
}) => {
  const { url, caption } = element;
  return (
    <div {...attributes}>
      <ImgWrapper contentEditable={false}>
        <Img src={url as string} />
        <ImgCaption>{caption as string}</ImgCaption>
      </ImgWrapper>
      {children}
    </div>
  );
};

export default withEmbedsImg;

import {
  POST_LOGIN,
  POST_LOGIN_FULFILLED,
  POST_LOGIN_REJECTED,
  postLogin,
  postLoginFulFilled,
  postLoginRejected
} from '../actions/login';

export interface Token {
  accessToken: string;
  refreshToken: string;
}

type Actions =
  | ReturnType<typeof postLogin>
  | ReturnType<typeof postLoginFulFilled>
  | ReturnType<typeof postLoginRejected>;

export interface Token {
  accessToken: string;
  refreshToken: string;
}

interface State {
  isFetching: boolean;
  token: Token;
  errorMessage: number | null;
}

const initialState: State = {
  isFetching: false,
  token: {
    accessToken: '',
    refreshToken: ''
  },
  errorMessage: null
};

export const login = (state = initialState, action: Actions) => {
  switch (action.type) {
    case POST_LOGIN:
      return {
        ...state,
        isFetching: true
      };
    case POST_LOGIN_FULFILLED:
      return {
        ...initialState,
        token: action.payload
      };
    case POST_LOGIN_REJECTED:
      return {
        ...initialState,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

import {
  getArticleById,
  createArticle,
  putArticle,
  deleteArticle
} from '../services/article';
import { AppThunk } from '../store';
import { Node } from 'slate';

export type ArticleCategory =
  | 'interview'
  | 'translation'
  | 'recommendation'
  | 'essay'
  | 'poem'
  | 'graphic';

export interface Article {
  id: number;
  vol: number;
  category: ArticleCategory;
  author: string;
  title: string;
  subtitle: string;
  content: Node[];
  image: string;
  description: string;
}

export interface ArticleInfo {
  total: number;
  page: number;
  length: number; // 每頁幾筆
  articles: Article[];
}

export type CreatedArticle = Omit<Article, 'id'>;

export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_ARTICLE_FULFILLED = 'GET_ARTICLE_FULFILLED';
export const GET_ARTICLE_REJECTED = 'GET_ARTICLE_REJECTED';
export const CREATE_ARTICLE_ACTION = 'CREATE_ARTICLE_ACTION';
export const CREATE_ARTICLE_FULFILLED = 'CREATE_ARTICLE_FULFILLED';
export const CREATE_ARTICLE_REJECTED = 'CREATE_ARTICLE_REJECTED';
export const EDIT_ARTICLE_ACTION = 'EDIT_ARTICLE_ACTION';
export const EDIT_ARTICLE_FULFILLED = 'EDIT_ARTICLE_FULFILLED';
export const EDIT_ARTICLE_REJECTED = 'EDIT_ARTICLE_REJECTED';
export const DELETE_ARTICLE_ACTION = 'DELETE_ARTICLE_ACTION';
export const DELETE_ARTICLE_FULFILLED = 'DELETE_ARTICLE_FULFILLED';
export const DELETE_ARTICLE_REJECTED = 'DELETE_ARTICLE_REJECTED';

export const getArticle = () => ({ type: GET_ARTICLE } as const);
export const getArticleFulfilled = (payload: Article) =>
  ({
    type: GET_ARTICLE_FULFILLED,
    payload
  } as const);
export const getArticleRejected = (payload: string) =>
  ({
    type: GET_ARTICLE_REJECTED,
    payload
  } as const);

export const fetchArticle = (id: number): AppThunk => async (
  dispatch,
  getState
) => {
  const articles = getState().article.articles;
  if (articles[id] && articles[id].id !== -1) return;

  try {
    dispatch(getArticle());
    const result = await getArticleById(id);
    dispatch(getArticleFulfilled(result));
  } catch (error) {
    dispatch(getArticleRejected(error.toString()));
  }
};

export const createArticleAction = () =>
  ({ type: CREATE_ARTICLE_ACTION } as const);

export const createArticleFulfilled = () =>
  ({
    type: CREATE_ARTICLE_FULFILLED
  } as const);

export const createArticleRejected = (payload: string) =>
  ({
    type: CREATE_ARTICLE_REJECTED,
    payload
  } as const);

export const fetchCreateArticle = (
  article: CreatedArticle
): AppThunk => async dispatch => {
  try {
    await createArticle(article);
    dispatch(createArticleFulfilled);
    window.location.href = '/admin/articles';
  } catch (error) {
    dispatch(createArticleRejected(error.toString()));
  }
};

export const editArticleAction = () => ({ type: EDIT_ARTICLE_ACTION } as const);

export const editArticleFulfilled = (payload: { id: number }) =>
  ({
    type: EDIT_ARTICLE_FULFILLED,
    payload
  } as const);

export const editArticleRejected = (payload: string) =>
  ({
    type: EDIT_ARTICLE_REJECTED,
    payload
  } as const);

export const fetchEditArticle = (
  article: Article
): AppThunk => async dispatch => {
  try {
    await putArticle(article);
    dispatch(editArticleFulfilled({ id: article.id }));
    window.location.href = '/admin/articles';
  } catch (error) {
    dispatch(editArticleRejected(error.toString()));
  }
};

export const deleteArticleAction = () =>
  ({ type: DELETE_ARTICLE_ACTION } as const);

export const deleteArticleFulfilled = () =>
  ({ type: DELETE_ARTICLE_FULFILLED } as const);

export const deleteArticleRejected = (payload: string) =>
  ({
    type: DELETE_ARTICLE_REJECTED,
    payload
  } as const);

export const fetchDeleteArticle = (id: number): AppThunk => async dispatch => {
  try {
    await deleteArticle(id);
    dispatch(deleteArticleFulfilled());
    window.location.href = '/admin/articles';
  } catch (error) {
    dispatch(deleteArticleRejected(error.toString()));
  }
};

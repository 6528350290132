import React, { Fragment } from 'react';

import ArticleCategoryList from './ArticleCategoryList';

export default class Home extends React.Component<{ personal?: boolean }> {
  render() {
    return (
      <Fragment>
        <ArticleCategoryList personal={this.props.personal} />
      </Fragment>
    );
  }
}

import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.ul<{ showSubNav: boolean }>`
  position: absolute;
  top: 30px;
  left: 0;
  padding: 10px 6px;
  display: ${props => (props.showSubNav ? 'block' : 'none')};
  width: 120%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    position: relative;
    top: 10px;
    padding: 0;
    display: block;
    width: 100%;
    background-color: #333333;
    box-shadow: none;
  }
`;

const NavLink = styled.a`
  margin-bottom: 3px;
  padding: 10px;
  display: block;
  color: #9d9d9d;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  border-bottom: 0px solid #9d9d9d;
  :hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

interface Props {
  showSubNav: boolean;
  subMenu: {
    title: string;
    link: string;
  }[];
}

const SubNav: FC<Props> = ({ subMenu, showSubNav }) => {
  return (
    <Wrapper showSubNav={showSubNav}>
      {subMenu.map(({ title, link }, idx) => (
        <li key={idx}>
          <NavLink href={link}>{title}</NavLink>
        </li>
      ))}
    </Wrapper>
  );
};

export default SubNav;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getLandingPageCategory,
  getLandingPagePersonal
} from '../../services/landingPage';
import ArticleBlock from './ArticleBlock';

interface Article {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  description: string;
  content: string;
  image: string;
}

interface Category {
  routePath: string;
  iconText: string;
  title: string;
  description: string;
  articles: Article[];
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  /* max-width: 750px; */
`;

const ArticleCategoryList = (props: { personal?: boolean }) => {
  const [articleCategoryList, setArticleCategoryList] = useState<Category[]>(
    []
  );

  useEffect(() => {
    setArticleCategoryList([]);
    if (props.personal) {
      getLandingPagePersonal()
        .then(data => {
          setArticleCategoryList(data);
        })
        .catch(error => console.error(error));
    } else {
      getLandingPageCategory()
        .then(data => {
          setArticleCategoryList(data);
        })
        .catch(error => console.error(error));
    }
  }, [props.personal]);

  return (
    <Wrapper>
      {articleCategoryList.map((item, idx) => {
        const { articles, iconText, title, description, routePath } = item;

        return (
          <ArticleBlock
            key={idx}
            articles={articles}
            iconText={iconText}
            title={title}
            description={description}
            routePath={routePath}
          />
        );
      })}
    </Wrapper>
  );
};

export default ArticleCategoryList;

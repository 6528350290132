import React, { FC, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { AddCircleOutlineOutlined, SearchOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { RootState } from '../../reducers';

import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';
import ArticleInfo from './ArticleInfo';
import {
  fetchArticleList,
  fetchMoreArticleList
} from '../../actions/articleList';
import LoadingIcon from '../../components/LoadingIcon';

const Wrapper = styled.div`
  padding: 6%;
  width: calc(100% - 280px);
  min-height: calc(100vh - 80px);
  background-color: #eee;
  border-radius: 100px 0px 0px 0px;
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    margin-bottom: 12px;
  }
`;

const Title = styled.h2`
  margin: 0 50px 0 0;
  color: #666;
  font-size: 24px;
  font-weight: 900;
`;

const BtnWithIcon = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px 0 12px;
  height: 30px;
  border-radius: 8px;
  transition: 0.5s;
  box-shadow: 0 0 20px #eee;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #7cd7eb 0%,
    #99edff 51%,
    #7cd7eb 100%
  );
  &:hover {
    background-position: bottom right;
  }
`;

const IconAdd = styled(AddCircleOutlineOutlined)`
  color: #fff;
  margin-right: 6px;
  font-size: 20px !important;
`;

const BtnWithIconText = styled.p`
  color: #fff;
  font-size: 16px;
`;

const SearchBar = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  width: 280px;
  height: 44px;
`;

const SearchInput = styled.input.attrs(props => ({
  type: 'input',
  placeholder: '請輸入關鍵字...'
}))`
  padding-left: 12px;
  color: #666;
  font-size: 18px;
  width: 236px;
  height: 44px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px 0 0 10px;
  &:focus {
    outline: none;
    border: 1px solid #7cd7eb;
  }
  &::placeholder {
    color: #ccc;
    font-size: 18px;
  }
`;

const SearchButton = styled.input.attrs(props => ({
  type: 'submit',
  value: ''
}))`
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 0 10px 10px 0;
  border: none;
  &:focus {
    outline: none;
  }
  transition: 0.5s;
  box-shadow: 0 0 20px #eee;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #7cd7eb 0%,
    #99edff 51%,
    #7cd7eb 100%
  );
  &:hover {
    background-position: bottom right;
  }
`;
const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  right: 6px;
  pointer-events: none;
  color: #fff;
  font-size: 32px !important;
`;

const tableTitle = ['標題', '作者', '類別', '期數', '編輯', '狀態'];

const Articles: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFetching, listInfo, errorMessage } = useSelector(
    (state: RootState) => state.articleList
  );
  const { articles, length } = listInfo;

  const handleGoToAddArticle = () => {
    history.push('/admin/article/add');
  };

  useEffect(() => {
    dispatch(fetchArticleList());
  }, [dispatch]);

  const handleOnLoadMore = useCallback(() => {
    dispatch(fetchMoreArticleList());
  }, [dispatch]);

  if (isFetching) {
    return <LoadingIcon />;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <Wrapper>
      <TitleContent>
        <TitleBox>
          <Title>所有文章</Title>
          <BtnWithIcon onClick={handleGoToAddArticle}>
            <IconAdd />
            <BtnWithIconText>新增文章</BtnWithIconText>
          </BtnWithIcon>
        </TitleBox>
        {/* <SearchBar onSubmit={handleSearch}>
          <SearchInput value={searchText} onChange={handleTextChange} />
          <SearchButton />
          <SearchIcon />
        </SearchBar> */}
      </TitleContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableTitle.map(title => (
                <TableCell key={title} align="left">
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {articles
              .map(item => {
                const { id } = item;
                return <ArticleInfo key={id} article={item} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* length 不等於10 表示回來的文章數不滿一頁(即是最後一頁) */}
      {length === 10 && <InfiniteScrollObserver loadMore={handleOnLoadMore} />}
    </Wrapper>
  );
};

export default Articles;

import React, { FC } from 'react';
import styled from 'styled-components';

interface Article {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  description: string;
  content: string;
  image: string;
}

const Wrapper = styled.div`
  display: flex;

  width: 100%;
  padding: 10px 20px;

  color: #333;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImgContainer = styled.a`
  display: flex;
  flex: 1 1 33%;
  justify-content: center;
  align-items: center;

  min-width: 0;
  width: 100%;
  height: 250px;
  overflow: hidden;

  background-color: #333;
  color: #9d9d9d;
  text-decoration: none;
  @media (max-width: 768px) {
    flex: none;
  }
`;
const Img = styled.img`
  display: block;
  max-width: 100%;
`;
const DefaultImageText = styled.p`
  font-size: 18px;
  color: #9d9d9d;
`;
const Content = styled.div`
  flex: 1 1 67%;

  padding: 10px 15px;
  overflow: hidden;

  font-size: 16px;
`;
const Title = styled.div`
  margin: 20px 0 15px 0;

  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  > a {
    text-decoration: underline;
    color: black;
  }
  @media (max-width: 768px) {
    margin: 5px 0 15px 0;
  }
`;
const SubTitle = styled.div`
  margin: 10px 0;

  font-weight: 500;
  font-size: 18px;
`;
const Text = styled.div`
  margin-top: 10px;

  font-size: 14px;
  line-height: 2;
`;

const ArticleCard: FC<Article> = ({
  id,
  title,
  subtitle,
  author,
  description,
  content,
  image
}) => {
  const href = `/article/${id}`;
  return (
    <Wrapper>
      <ImgContainer href={href}>
        {image ? (
          <Img src={image} alt={title} />
        ) : (
          <DefaultImageText>失蹤者之城</DefaultImageText>
        )}
      </ImgContainer>
      <Content>
        <Title>
          <a href={href}>{title}</a>
        </Title>
        <SubTitle>{subtitle}</SubTitle>
        <Text>{description}</Text>
      </Content>
    </Wrapper>
  );
};

export default ArticleCard;

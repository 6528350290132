import React, {
  FC,
  useEffect,
  ChangeEvent,
  useState,
  useMemo,
  useCallback
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  fetchCreateImage,
  fetchImage,
  fetchDeleteImage,
  fetchMoreImage,
  Image
} from '../../../actions/image';
import { RootState } from '../../../reducers';
import { LoadingWrapper } from '../../../components/TextEditor/ImageModal';
import InfiniteScrollObserver from '../../../components/InfiniteScrollObserver';

interface Props {
  isOpen: boolean;
  handleCloseDialog: () => void;
  handleCoverSelect?: (url: string) => void;
}

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 10px;
`;
const Album = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
`;
const AlbumImage = styled.img<{ active: boolean }>`
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 3px solid ${({ active }) => (active ? 'red' : 'transparent')};
  &:hover {
    border: 3px solid gray;
  }
  object-fit: contain;
`;

const ImageModal: FC<Props> = props => {
  const { isOpen, handleCloseDialog, handleCoverSelect } = props;
  const dispatch = useDispatch();
  const { listInfo, isFetching } = useSelector(
    (state: RootState) => state.image
  );
  const { length, images } = listInfo;
  const [selectedImgId, setSelectedImgId] = useState<number>(-1);

  const imageSet = useMemo(
    () => new Map((images as Image[]).map(image => [image.id, image])),
    [images]
  );

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const handleOnLoadMore = useCallback(() => {
    dispatch(fetchMoreImage());
  }, [dispatch]);

  function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    const file = files && files[0];
    if (file) {
      dispatch(fetchCreateImage(file));
    }
  }

  const handleOnSelectImg = () => {
    if (selectedImgId > 0) {
      const { url } = imageSet.get(selectedImgId)!;
      if (handleCoverSelect) handleCoverSelect(url);
    }
    handleCloseDialog();
  };

  const handleOnDeleteImg = () => {
    if (selectedImgId > 0) {
      dispatch(fetchDeleteImage(selectedImgId));
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogContent>
        <Header>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleUploadFile} />
          </Button>
          <IconButton onClick={handleOnDeleteImg}>
            <Delete />
          </IconButton>
        </Header>
        <Album>
          {(images as Image[]).map(img => {
            const { id, url } = img;
            return (
              <AlbumImage
                src={url}
                key={id}
                onClick={() => setSelectedImgId(id)}
                active={id === selectedImgId}
              />
            );
          })}
        </Album>
        {/* length 不等於20 表示回來的文章數不滿一頁(即是最後一頁) */}
        {length === 20 && (
          <InfiniteScrollObserver loadMore={handleOnLoadMore} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnSelectImg}>確認</Button>
      </DialogActions>

      {isFetching && (
        <LoadingWrapper>
          <CircularProgress color="primary" size={64} />
        </LoadingWrapper>
      )}
    </Dialog>
  );
};

export default ImageModal;

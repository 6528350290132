import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';

import Sidebar from './Sidebar';
import Articles from './Articles';
import AddEditArticle from './AddEditArticle';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const FakeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 280px);
  font-size: 50px;
`;

const tabList = [
  { title: '統計', icon: 'IconChart', routePath: '' },
  { title: '文章', icon: 'IconFile', routePath: 'articles' },
  { title: '媒體', icon: 'IconPhoto', routePath: 'media' },
  { title: '留言', icon: 'IconComment', routePath: 'message' }
];

const routes = [
  {
    path: '/admin',
    exact: true,
    main: () => <Statics />
  },
  {
    path: '/admin/articles',
    main: () => <Articles />
  },
  {
    path: '/admin/media',
    main: () => <Media />
  },
  {
    path: '/admin/message',
    main: () => <Message />
  },
  {
    path: '/admin/article/:mode(add|edit)/:id?',
    main: () => <AddEditArticle />
  }
];

const Statics = () => {
  return <FakeWrapper>Statics</FakeWrapper>;
};
const Media = () => {
  return <FakeWrapper>Media</FakeWrapper>;
};
const Message = () => {
  return <FakeWrapper>Message</FakeWrapper>;
};

const App = () => {
  return (
    <Router>
      <Wrapper>
        <Sidebar tabList={tabList} />
        <Switch>
          {routes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
      </Wrapper>
    </Router>
  );
};

export default App;

import { getArticleListInfo } from '../services/articleList';
import { AppThunk } from '../store';
import { ArticleInfo } from '../actions/article';

export const GET_ARTICLELIST = 'GET_ARTICLELIST';
export const GET_ARTICLELIST_FULFILLED = 'GET_ARTICLELIST_FULFILLED';
export const GET_ARTICLELIST_REJECTED = 'GET_ARTICLELIST_REJECTED';
export const LOAD_MORE_ARTICLELIST = 'LOAD_MODE_ARTICLELIST';
export const LOAD_MORE_ARTICLELIST_FULFILLED =
  'LOAD_MORE_ARTICLELIST_FULFILLED';
export const LOAD_MORE_ARTICLELIST_REJECTED = 'LOAD_MORE_ARTICLELIST_REJECTED';

export const getArticleList = () => ({ type: GET_ARTICLELIST } as const);
export const getArticleListFulfilled = (payload: ArticleInfo) =>
  ({
    type: GET_ARTICLELIST_FULFILLED,
    payload
  } as const);
export const getArticleListRejected = (payload: string) =>
  ({
    type: GET_ARTICLELIST_REJECTED,
    payload
  } as const);
export const loadMoreArticleList = () =>
  ({ type: LOAD_MORE_ARTICLELIST } as const);
export const loadMoreArticleListFulfilled = (payload: ArticleInfo) =>
  ({
    type: LOAD_MORE_ARTICLELIST_FULFILLED,
    payload
  } as const);
export const loadMoreArticleListRejected = (payload: string) =>
  ({
    type: LOAD_MORE_ARTICLELIST_REJECTED,
    payload
  } as const);

export const fetchArticleList = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getArticleList());
    const result = await getArticleListInfo(0);
    dispatch(getArticleListFulfilled(result));
  } catch (error) {
    dispatch(getArticleListRejected(error.toString()));
  }
};

export const fetchMoreArticleList = (): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(loadMoreArticleList());
    const { page } = getState().articleList.listInfo;
    const result = await getArticleListInfo(page + 1);
    dispatch(loadMoreArticleListFulfilled(result));
  } catch (error) {
    dispatch(loadMoreArticleListRejected(error.toString()));
  }
};

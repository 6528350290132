import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Nav from './Nav';

const Wrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 10% 0 15%;
  background-color: #333333;
  @media (max-width: 768px) {
    padding: 0 5% 0 10%;
  }
`;

const OverlayShadow = styled.div`
  position: fixed;
  z-index: 1;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Logo = styled.a`
  color: #9d9d9d;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
`;

const HamburgerWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
`;

const Hamburger = styled.div<{ status: boolean }>`
  @media (max-width: 768px) {
    position: relative;
    z-index: 2;
    width: 26px;
    height: 3px;
    background-color: ${props => (props.status ? '#333' : '#9d9d9d')};
    transition: background-color 0.4s;
    border-radius: 2px;
    ::before {
      position: absolute;
      top: ${props => (props.status ? '0px' : '8px')};
      content: '';
      display: block;
      width: 22px;
      height: 3px;
      background-color: #9d9d9d;
      border-radius: 2px;
      transition: transform 0.4s ease-out;
      transform: ${props => (props.status ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
    ::after {
      position: absolute;
      bottom: ${props => (props.status ? '0px' : '8px')};
      content: '';
      display: block;
      width: 22px;
      height: 3px;
      background-color: #9d9d9d;
      border-radius: 2px;
      transition: transform 0.4s ease-out;
      transform: ${props => (props.status ? 'rotate(-45deg)' : 'rotate(0deg)')};
    }
  }
`;

const menu = [
  { title: '訪談', icon: 'IconInterView', link: '/categories/interview' },
  { title: '翻譯', icon: 'IconTranslate', link: '/categories/translation' },
  { title: '推薦', icon: 'IconRecommend', link: '/categories/recommendation' },
  {
    title: '專欄',
    icon: 'IconColumn',
    link: '/categories/personal',
    subMenu: [
      { title: '詩詞', link: '/categories/poem' },
      { title: '散文', link: '/categories/essay' },
      { title: '小說', link: '/categories/novel' },
      { title: '圖像', link: '/categories/graphic' }
    ]
  },
  { title: '關於', icon: 'IconAbout', link: '/about' }
];

const Header = () => {
  const logoRef = useRef<HTMLHeadingElement>(null);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLUListElement>(null);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const isLogo =
        logoRef.current && logoRef.current.contains(e.target as Node);
      const isHamburger =
        hamburgerRef.current && hamburgerRef.current.contains(e.target as Node);
      const isNav = navRef.current && navRef.current.contains(e.target as Node);

      if (isHamburger) {
        return;
      } else if (!isLogo && !isNav) {
        setShowNav(false);
      }
      return;
    };

    if (showNav) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNav]);

  useEffect(() => {
    showNav
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  });

  return (
    <Wrapper>
      <h1 ref={logoRef}>
        <Logo href="/">失蹤者之城</Logo>
      </h1>

      <HamburgerWrapper ref={hamburgerRef} onClick={() => setShowNav(!showNav)}>
        <Hamburger status={showNav} />
      </HamburgerWrapper>

      {showNav && <OverlayShadow />}

      <Nav menu={menu} showNav={showNav} ref={navRef} />
    </Wrapper>
  );
};

export default Header;

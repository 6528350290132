import { createApiClient } from './apiClient';
import { ImageInfo } from '../actions/image';

const apiClient = createApiClient();

export const getImages = async (page: number) => {
  const response = await apiClient.get<ImageInfo>(`/get/image-list`, {
    params: {
      page,
      length: 20
    }
  });
  return response.data;
};

export const createImage = async (image: File) => {
  const formData = new FormData();
  const { name } = image;
  formData.append('image', image);
  formData.append('name', name);

  const response = await apiClient.post('/upload/images', formData);
  return response.data;
};

export const deleteImg = async (id: number) => {
  const params = {
    imageId: [id]
  };
  const response = await apiClient.delete('/delete/images', {
    data: params
  });
  return response.data;
};

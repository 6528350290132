import { createApiClient } from './apiClient';
import { ArticleInfo } from '../actions/article';

const apiClient = createApiClient();

export const getArticleListInfo = async (page: number) => {
  const response = await apiClient.get<ArticleInfo>('/get/article-list', {
    params: {
      page,
      length: 10
    }
  });

  return response.data;
};

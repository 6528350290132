import { applyMiddleware, createStore, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './reducers';

const preloadedState = {};

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const composedEnhancers = composeWithDevTools(middlewareEnhancer);
const store = createStore(rootReducer, preloadedState, composedEnhancers);

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;

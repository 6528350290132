import { createApiClient } from './apiClient';
import { Article, CreatedArticle } from '../actions/article';

const apiClient = createApiClient();

export const getArticleById = async (id: number) => {
  const response = await apiClient.get<Article>(`/get/articles/${id}`);
  return response.data;
};

export const createArticle = async (article: CreatedArticle) => {
  const response = await apiClient.post('/create/articles', article);
  return response.data;
};

export const putArticle = async (article: CreatedArticle) => {
  const response = await apiClient.put('/update/articles', article);
  return response.data;
};

export const deleteArticle = async (id: number) => {
  const response = await apiClient.delete(`/delete/articles/${id}`);
  return response.data;
};

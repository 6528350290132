import React from 'react';
import { Editor, Transforms, Range } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import styled from 'styled-components';
import { Link, LinkOff } from '@material-ui/icons';
import isUrl from 'is-url';

const Button = styled.button<{ active: boolean }>`
  margin-right: 15px;
  border: none;
  background-color: transparent;

  color: ${props => (props.active ? 'black' : '#ccc')};

  > svg {
    font-size: 24px;
  }
`;

const withLinks = (editor: ReactEditor): ReactEditor => {
  const { insertText, isInline } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  return editor;
};

const insertLink = (editor: ReactEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor: ReactEditor) => {
  const [link]: any = Editor.nodes(editor, { match: n => n.type === 'link' });
  return !!link;
};

export const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

export const wrapLink = (editor: ReactEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const LinkButton = () => {
  const editor = useSlate();
  return (
    <Button
      active={true}
      onMouseDown={event => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Link />
    </Button>
  );
};

export const LinkOffButton = () => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault();
        unwrapLink(editor);
      }}
    >
      <LinkOff />
    </Button>
  );
};

export default withLinks;

import {
  CategoryInfo,
  GET_CATEGORY,
  GET_CATEGORY_FULFILLED,
  GET_CATEGORY_REJECTED,
  LOAD_MORE_CATEGORY,
  LOAD_MORE_CATEGORY_FULFILLED,
  LOAD_MORE_CATEGORY_REJECTED,
  getCategory,
  getCategoryFulfilled,
  getCategoryRejected,
  loadMoreCategory,
  loadMoreCategoryFulfilled,
  loadMoreCategoryRejected
} from '../actions/category';

type Actions =
  | ReturnType<typeof getCategory>
  | ReturnType<typeof getCategoryFulfilled>
  | ReturnType<typeof getCategoryRejected>
  | ReturnType<typeof loadMoreCategory>
  | ReturnType<typeof loadMoreCategoryFulfilled>
  | ReturnType<typeof loadMoreCategoryRejected>;

interface State {
  isFetching: boolean;
  isLoadingMore: boolean;
  categoryInfo: CategoryInfo;
  errorMessage: string;
}

const initialState: State = {
  isFetching: false,
  isLoadingMore: false,
  categoryInfo: {
    total: 0,
    page: 0,
    length: 0,
    articles: []
  },
  errorMessage: ''
};

export const category = (state = initialState, action: Actions) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        isFetching: true
      };
    case GET_CATEGORY_FULFILLED:
      return {
        ...state,
        isFetching: false,
        categoryInfo: action.payload,
        errorMessage: ''
      };
    case GET_CATEGORY_REJECTED:
      return {
        ...state,
        isFetching: false,
        categoryInfo: initialState.categoryInfo,
        errorMessage: action.payload
      };
    case LOAD_MORE_CATEGORY:
      return {
        ...state,
        isLoadingMore: true
      };
    case LOAD_MORE_CATEGORY_FULFILLED:
      return {
        ...state,
        isLoadingMore: false,
        categoryInfo: {
          ...action.payload,
          articles: state.categoryInfo.articles.concat(action.payload.articles)
        }
      };
    case LOAD_MORE_CATEGORY_REJECTED:
      return {
        ...state,
        isLoadingMore: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

import React, { FC, useCallback, useMemo } from 'react';
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps
} from 'slate-react';
import { withHistory } from 'slate-history';
import withLinks from './withLinks';
import { createEditor, Node } from 'slate';
import styled from 'styled-components';
import withEmbedsImg, { YoutubeElement, ImageElement } from './withEmbedsImg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  background-color: white;
  font-size: 16px;
  line-height: 1.8;
  color: #333;

  strong {
    font-weight: bold;
  }
  u {
    font-decoration: underlne;
  }
  em {
    font-style: italic;
  }
  code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`;

const EditableWrapper = styled.div`
  margin-top: 50px;
`;

interface Props {
  content: Node[];
}

const TextReader: FC<Props> = props => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withEmbedsImg(withLinks(withHistory(withReact(createEditor())))),
    []
  );
  return (
    <Wrapper>
      <Slate editor={editor} value={props.content} onChange={() => {}}>
        <EditableWrapper>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly
          />
        </EditableWrapper>
      </Slate>
    </Wrapper>
  );
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <a {...attributes} href={element.url as string}>
          {children}
        </a>
      );
    case 'youtubeIframe':
      return <YoutubeElement {...props} />;
    case 'image':
      return <ImageElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default TextReader;

import {
  ImageInfo,
  GET_IMAGES,
  GET_IMAGES_FULFILLED,
  GET_IMAGES_REJECTED,
  LOAD_MORE_IMAGES,
  LOAD_MORE_IMAGES_FULFILLED,
  LOAD_MORE_IMAGES_REJECTED,
  CREATE_IMAGE_ACTION,
  CREATE_IMAGE_FULFILLED,
  CREATE_IMAGE_REJECTED,
  DELETE_IMAGE_ACTION,
  DELETE_IMAGE_FULFILLED,
  DELETE_IMAGE_REJECTED,
  getImages,
  getImagesFulfilled,
  getImagesRejected,
  loadMoreImages,
  loadMoreImagesFulfilled,
  loadMoreImagesRejected,
  createImageAction,
  createImageFulfilled,
  createImageRejected,
  deleteImageAction,
  deleteImageFulfilled,
  deleteImageRejected
} from '../actions/image';

type Actions =
  | ReturnType<typeof getImages>
  | ReturnType<typeof getImagesFulfilled>
  | ReturnType<typeof getImagesRejected>
  | ReturnType<typeof loadMoreImages>
  | ReturnType<typeof loadMoreImagesFulfilled>
  | ReturnType<typeof loadMoreImagesRejected>
  | ReturnType<typeof createImageAction>
  | ReturnType<typeof createImageFulfilled>
  | ReturnType<typeof createImageRejected>
  | ReturnType<typeof deleteImageAction>
  | ReturnType<typeof deleteImageFulfilled>
  | ReturnType<typeof deleteImageRejected>;

interface State {
  listInfo: ImageInfo;
  isLoadingMore: boolean;
  isFetching: boolean;
  errorMessage: string;
  createErrorMessage: string;
  deleteErrorMessage: string;
}

export const initialState: State = {
  listInfo: {
    total: 0,
    page: 0,
    length: 0,
    images: []
  },
  isLoadingMore: false,
  isFetching: false,
  errorMessage: '',
  createErrorMessage: '',
  deleteErrorMessage: ''
};

export const image = (state = initialState, action: Actions) => {
  switch (action.type) {
    case GET_IMAGES:
      return {
        ...state,
        isFetching: true
      };
    case GET_IMAGES_FULFILLED:
      return {
        ...state,
        listInfo: action.payload,
        isFetching: false
      };
    case GET_IMAGES_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false
      };
    case LOAD_MORE_IMAGES:
      return {
        ...state,
        isLoadingMore: true
      };
    case LOAD_MORE_IMAGES_FULFILLED:
      return {
        ...state,
        listInfo: {
          ...action.payload,
          images: state.listInfo.images.concat(action.payload.images)
        },
        isLoadingMore: false
      };
    case LOAD_MORE_IMAGES_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isLoadingMore: false
      };
    case CREATE_IMAGE_REJECTED:
      return {
        ...state,
        isFetching: false,
        createErrorMessage: action.payload
      };
    case CREATE_IMAGE_FULFILLED:
      return {
        ...state,
        isFetching: false
      };
    case CREATE_IMAGE_ACTION:
      return {
        ...state,
        isFetching: true
      };
    case DELETE_IMAGE_REJECTED:
      return {
        ...state,
        isFetching: false,
        deleteErrorMessage: action.payload
      };
    case DELETE_IMAGE_FULFILLED:
      return {
        ...state,
        isFetching: false
      };
    case DELETE_IMAGE_ACTION:
      return {
        ...state,
        isFetching: true
      };
    default:
      return state;
  }
};

import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Storage from '../services/storage';

interface Props {
  key?: number;
  path: string;
  exact?: boolean;
}

const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        Storage.getAccessToken() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
